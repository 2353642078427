var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "contain",
    style: {
      '--swiperNum': _vm.list && _vm.list.length
    }
  }, [_c('div', {
    attrs: {
      "id": "hy-swiper"
    }
  }, [_c('div', {
    staticClass: "swiper",
    class: _vm.containName
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-item"
    }, [_vm._t("default", null, {
      "item": item,
      "index": index
    })], 2);
  }), 0)]), _c('div', {
    staticClass: "swiper-circle"
  }, [_c('ul', _vm._l(_vm.swiperItemCount, function (item) {
    return _c('li', {
      class: _vm.currentIndex == item ? 'swiperItemActive' : '',
      on: {
        "click": function ($event) {
          return _vm.onCount(item);
        }
      }
    });
  }), 0)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };