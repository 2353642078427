import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "layout",
  inject: ["reload"],
  data() {
    return {};
  },
  watch: {
    isWallet: {
      async handler(bool) {
        // //验证链接和登录状态
        // const walletConnected = localStorage.getItem("WALLET_CONNECTED");
        // // console.log("walletConnected", walletConnected, bool);
        // console.log("****************isWallet***************", walletConnected, !bool);
        // if (walletConnected && !bool) {
        //   await this.doConnect(true);
        // }
        // await this.checkWallet();
        // this.vailLogin();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...walletVuex.mapState(["connect", "isAddress", "netIdError", "address", "isMobile"]),
    ...walletVuex.mapGetters(["isWallet"])
  },
  methods: {
    ...walletVuex.mapActions(["doConnect", "checkWallet"])
  }
};