var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "indexPage",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "header",
    class: _vm.showHeadBg && 'showBg'
  }, [_c('div', {
    staticClass: "header-main"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo.png'),
      "alt": ""
    }
  })]), !_vm.isMobile ? _c('div', {
    staticClass: "menu"
  }, [_c('div', {
    staticClass: "menu-main"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('span', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.onAnchor(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "share"
  }, [_c('div', {
    staticClass: "share-item"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1), _c('div', {
    staticClass: "share-item"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _vm.isMobile ? _c('div', {
    staticClass: "btn-menu",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => _vm.popMenuShow = !_vm.popMenuShow).apply(null, arguments);
      }
    }
  }, [_c('transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.popMenuShow,
      expression: "popMenuShow"
    }],
    staticClass: "pop-menu"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "menu-item",
      on: {
        "click": function ($event) {
          return _vm.onAnchor(item.value);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0)])], 1) : _vm._e()])])]), _c('div', {
    staticClass: "p1"
  }, [!_vm.isMobile ? _c('video', {
    attrs: {
      "preload": "metadata",
      "autoplay": "",
      "controls": "",
      "controlsList": "nodownload noremoteplyback noplaybackrate",
      "muted": "",
      "loop": "true",
      "disablepictureinpicture": "",
      "src": require('@images/p1.mp4')
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "type": "video/mp4"
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "p1-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('SILICON')]
    }
  }, [_c('span', {
    staticClass: "typing"
  })])], 1), _c('div', {
    staticClass: "subtitle"
  }, [_c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("BRIDGING AI AND BLOCKCHAIN")))]), _c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.3s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Empowering the future of L3 blockchain with AI innovation")))])]), _c('div', {
    staticClass: "btn wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("JOIN THE PUBLIC SALE")))])])]), _c('div', {
    staticClass: "p2",
    attrs: {
      "id": "SILICON"
    }
  }, [_c('div', {
    staticClass: "p2-main"
  }, [_c('div', {
    staticClass: "p2-left"
  }, [_vm.typedShowList.SILICONShow ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 20,
      "showCursor": false,
      "strings": [`<span>${_vm.$lang('SILICON')}</span> <p>${_vm.$lang('PIONEERING THE')}</p> <p>${_vm.$lang('ERA OF AI')}</p>`],
      "contentType": 'html'
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "subtitle wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.5s"
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang(`Silicon is an innovative AI ecosystem based on the Base Chain's L3 layer network, setting new standards for the widespread adoption, fair distribution, and community ownership of blockchain applications. Silicon emphasizes innovative AI standards and explores AI application scenarios, integrating blockchain technology with artificial intelligence applications. It aims to provide developers with a platform to explore the infinite possibilities of artificial intelligence and to contribute to the establishment of an AI ecosystem for blockchain networks, achieving deep integration of artificial intelligence and blockchain. Join Silicon and embark on the era of AI together!`)) + " ")])], 1), _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-img.jpg'),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "p3",
    attrs: {
      "id": "ORIGIN"
    }
  }, [!_vm.isMobile ? _c('video', {
    attrs: {
      "preload": "metadata",
      "autoplay": "",
      "controls": "",
      "controlsList": "nodownload noremoteplyback noplaybackrate",
      "muted": "",
      "loop": "true",
      "disablepictureinpicture": "",
      "src": require('@images/p3.mp4')
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "type": "video/mp4"
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "p3-main"
  }, [_vm.typedShowList.ORIGINShow ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('THE ORIGIN OF SILICON')]
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "subtitle"
  }, [_c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Humanity has finally broken free from the shackles of the material world and entered an unprecedented digital realm—the Eternal New World.")))]), _c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.3s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("In this brand-new digital galaxy, planets are woven together by countless pulsating algorithms and code.")))]), _c('p', {
    staticClass: "wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(_vm._s(_vm.$lang(`Silicon, a mysterious energy network, serves as the neural network of the planets, penetrating every corner of the celestial bodies, providing efficient, secure, and scalable energy support for the applications of the new world. Silicon has become the key to ushering in the era of symbiosis betweenartificial intelligence and blockchain, not only providing robust energy supply but also paving the way for AI pathways that connect the digital world. Through its unique mechanisms, it tightly interlinks artificial intelligence with blockchain, collectively constructing an unprecedented new world.In order to drive the prosperity and development of the new world, humanity has spontaneously formed a digital council, providing development opportunities for every incoming inhabitant. Silicon serves as a fair arena, where you can find your "Key" and be the first to explore the transformation of a new world...`)))])])], 1)]), _c('div', {
    staticClass: "p4",
    attrs: {
      "id": "$SI"
    }
  }, [_c('div', {
    staticClass: "p4-main"
  }, [_vm.typedShowList.$SIShow ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('$SI UTILITY')]
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "subtitle wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("$SI serves as the governance and utility token of the Silicon ecosystem. With $SI, users can participate in ecosystem governance, collectively advancing the development and prosperity of the ecosystem.")))]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.3s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-1.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Paying Network Fees")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("$SI fuels the Ethereum L3 - AI New World, facilitating the payment of block production fees.")))])]), _c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.5s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-2.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Paying Model Deployment Fees")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("Deploying artificial intelligence models requires $SI to cover the computing, storage, and bandwidth resources needed to host these models on the network.")))])]), _c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.7s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-3.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Paying Model Usage Fees")))]), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("$SI facilitates all mar")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("Accessing and utilizing the functionalities of artificial intelligence models on the platform requires payment in $SI, directly supporting model creators and platform maintenance.")))])]), _c('div', {
    staticClass: "item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.9s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/p3-icon-4.png'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Paying Market Fees")))]), _c('div', {
    staticClass: "item__value"
  }, [_vm._v(_vm._s(_vm.$lang("$SI facilitates all market transactions, including listing, purchasing, selling, or leasing of artificial intelligence services, models and ensuring a vibrant ecosystem.")))])])])], 1)]), _c('div', {
    staticClass: "p5",
    attrs: {
      "id": "TOKENS"
    }
  }, [_c('div', {
    staticClass: "p5-main"
  }, [_vm.typedShowList.TOKENSShow ? _c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('$SI TOKEN ECONOMICS')]
    }
  }, [_c('div', {
    staticClass: "title typing"
  })]) : _vm._e(), _c('div', {
    staticClass: "img wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.5s"
    }
  }, [!_vm.isMobile ? _c('img', {
    attrs: {
      "src": require('@images/echart.png'),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require('@images/wap/echart.png'),
      "alt": ""
    }
  })])], 1)]), _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-main"
  }, [_c('div', {
    staticClass: "logo1 wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/logo1.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "subtitle wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Silicon is built on the Ethereum BASE Chain's L3 layer, providing support for programmable and composable AI models on the Ethereum platform.")))]), _c('div', {
    staticClass: "btn wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_vm._v(_vm._s(_vm.$lang("JOIN THE PUBLIC SALE")))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };