import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popDialog",
  props: {
    show: Boolean,
    className: {
      type: String,
      default: ""
    },
    isClose: {
      type: Boolean,
      default: true
    },
    isCustomizeCloseEvent: {
      type: Boolean,
      default: false
    },
    closeModel: {
      default: false,
      type: Boolean
    },
    //是否可以通过点击 modal 关闭 Dialog
    modal: {
      default: false,
      type: Boolean
    },
    width: {
      type: String,
      default: "50vw"
    },
    height: {
      type: String,
      default: "50vh"
    },
    isRadio: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  watch: {
    show() {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        if (body.append) {
          body.append(this.$el);
        } else {
          body.appendChild(this.$el);
        }
      });
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    closeFn() {
      console.log("this.isCustomizeCloseEven", this.isCustomizeCloseEvent);
      if (this.isCustomizeCloseEvent) {
        this.$emit("closeFn");
      } else {
        this.$emit("update:show", false);
        this.$emit("closeFn");
      }
    }
  }
};