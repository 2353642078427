import "animate.css";
import Vue from "vue";
import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);
import { WOW } from "wowjs";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
function throttle(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}
export default {
  name: "index",
  inject: ["reload"],
  computed: {
    ...walletVuex.mapState(["isMobile", "isLang"]),
    ...walletVuex.mapGetters(["isWallet"]),
    throttle() {
      return throttle;
    }
  },
  data() {
    return {
      showHeadBg: false,
      scrollTop: 0,
      menuList: [{
        label: this.$lang("SILICON"),
        value: "SILICON"
      }, {
        label: this.$lang("ORIGIN"),
        value: "ORIGIN"
      }, {
        label: this.$lang("$SI"),
        value: "$SI"
      }, {
        label: this.$lang("TOKENS"),
        value: "TOKENS"
      }],
      popMenuShow: false,
      menuTopList: [],
      typedShowList: {
        SILICONShow: false,
        ORIGINShow: false,
        $SIShow: false,
        TOKENSShow: false
      }
    };
  },
  watch: {
    scrollTop: {
      handler(val) {
        console.log("val", val);
        this.showHeadBg = val >= 100 ? true : false;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onAnchor(value) {
      document.documentElement.scrollTop = document.body.scrollTop = document.getElementById(value).offsetTop;
    }
  },
  destroyed() {
    window.removeEventListener("click", () => this.popMenuShow = false);
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        live: true,
        mobile: true
      });
      wow.init();
      this.menuTopList = this.menuList.map(item => {
        return {
          offsetTop: document.getElementById(item.value).offsetTop,
          value: item.value
        };
      });
      const _throttle = () => {
        const bodyOffsetHeight = window.innerHeight;
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        that.scrollTop = scrollTop;
        const total = scrollTop + bodyOffsetHeight;
        this.menuTopList.map(it => {
          if (total >= it.offsetTop) {
            this.typedShowList[`${it.value}Show`] = true;
          }
        });
      };
      _throttle();
      document.body.onscroll = this.throttle(_throttle, 100);
      document.body.addEventListener("click", () => this.popMenuShow = false);
    });
  }
};