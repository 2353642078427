var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "pop-dialog",
    class: [_vm.className, _vm.isMobile && 'wap']
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        _vm.modal && _vm.closeFn();
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    key: "dialog",
    staticClass: "dialog-main",
    style: {
      width: _vm.width,
      height: _vm.height,
      borderRadius: _vm.isRadio ? _vm.remTran(15) : 0
    }
  }, [_vm.isClose ? _c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.closeFn.apply(null, arguments);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1) : _vm._e(), _vm._t("default")], 2)])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };